import config from '../config/config';
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
export default class Header extends Component {

  render() {
    return (
      <>
        <div className='wavefooter'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4'>
                <div id="site-logo" class="site-logo"><a href="/">
                  <img src="images/ESP LOGO NEW-2.png" alt="Engitech" /></a></div>
              </div>
            </div>
          </div>
        </div>
        <footer id="site-footer" className="site-footer footer-v2" >
          <div className="container mt-3 pt-2" style={{ borderTop: "1px solid #cccccc75" }}>
            <div className="row justify-content-center mt-3">
              <div className="col-md-3 col-sm-6 p-2">
                <div className="contact-info box-style2 ft-contact-info pl-5">
                  <h6>About Us</h6>
                  <p>ESPSofttech is a leading blockchain development company, assists businesses in harnessing the true potential of blockchain technology.
                    Contact us now to get custom blockchain development service or business consultancy.</p>
                </div>
                <div className='last-icon'>
                  <a className="twitter" href="https://twitter.com/Espsofttechpltd" target="_blank">
                    <svg height="1em" fill='#fff'
                    width="1em" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <path d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"></path>
                    </svg>
                  </a>
                  <a className="facebook" href="https://www.facebook.com/espsofttechfb/" target="_blank"><i className="fa fa-facebook-f" /></a>
                  <a className="linkedin" href="https://www.linkedin.com/in/rahulb-espsofttech/" target="_blank"><i className="fa fa-linkedin-in" /></a>
                  <a className="instagram" href="https://www.instagram.com/esp_softtechpvtltd/" target="_blank"><i className="fa fa-instagram" /></a>
                  <a className="instagram" href="https://in.pinterest.com/Espsofttech" target="_blank"><i class="fa fa-pinterest" aria-hidden="true"></i></a>
                  <a className="instagram" href="https://www.youtube.com/channel/UCewN7DpEMgs3bb8IDqO2tUA" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 p-2">
                <div className="contact-info box-style2 ft-contact-info pl-5">
                  <h6>Address</h6>
                  <p>601-602, 6th Floor, Princes' Business Skyline, Vijay Nagar, Indore, Madhya Pradesh 452010 </p>                  <p>110 William Street Perth 6000, Australia</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 p-2">
                <div className="contact-info box-style2 ft-contact-info pl-5">
                  <h6>Contact</h6>
           
                  <p aria-hidden="true">For Business Enquiries -</p>
                  <p> <i class="fa fa-chevron-right" aria-hidden="true"></i>business@espsofttech.com</p>
                  <p><i class="fa fa-chevron-right" aria-hidden="true"></i>+91-6267602662</p>
                  <p aria-hidden="true">For career -</p>
                  <p> <i class="fa fa-chevron-right" aria-hidden="true"></i>career@espsofttech.com</p>
                  <p><i class="fa fa-chevron-right" aria-hidden="true"></i>+91-8109784984</p>
            
                </div>
              </div>
              <div className="col-md-3 col-sm-6 p-2">
                <div className="contact-info box-style2 ft-contact-info pl-5">
                  <h6>Services</h6>
                  <p><i class="fa fa-chevron-right" aria-hidden="true"></i><Link to={`${config.baseUrl}Blockchain_service`}>Blockchain Development</Link></p>
                  <p><i class="fa fa-chevron-right" aria-hidden="true"></i><Link to={`${config.baseUrl}Nft_development`}>NFT Development</Link></p>
                  <p><i class="fa fa-chevron-right" aria-hidden="true"></i><Link to={`${config.baseUrl}Defi_development`}>Defi Development</Link></p>
                  <p><i class="fa fa-chevron-right" aria-hidden="true"></i><Link to={`${config.baseUrl}Web_development`}>Web Development</Link></p>
                  <p><i class="fa fa-chevron-right" aria-hidden="true"></i><Link to={`${config.baseUrl}Mobile_development`}>Mobile Development</Link></p>
                </div>
              </div>
            </div>
            <div className='copy_right'>
              <p className="copyright-text v2">Copyright @ 2023&nbsp;<a href="#" style={{ color: '#43baff' }}>ESP Softtech Pvt Ltd</a>. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
      </>
    )
  }
}