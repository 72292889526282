import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import Fade from 'react-reveal/Fade';
export default class Portfolio_blockchain extends Component {

    constructor(props) {
        super(props)
    }
    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

    }
    render() {
        return (
            <>
                <Header />
                <section className='mobile-background all-bg'>
                    <div className='container-fluid p-0'>
                        <div class="elementor-background-overlay"></div>
                        <div className='portfolio-head'>
                            <h1>Mobile development</h1>
                            <p>Espsofttech mobile development team has exceptional experience in creating native, hybrid, and cross-platform mobile apps.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container-fluid'>
                        {/* <div className='row'>
                            <div className='background'>
                            <div class="elementor-background-overlay"></div>
                                <div className='portfolio-head'>
                                    <h2>Mobile development</h2>
                                    <p>Espsofttech mobile development team has exceptional experience in creating native, hybrid, and cross-platform mobile apps.</p>
                                </div>
                            </div>
                        </div> */}
                        <div className='container mt-5 pt-5'>
                            <div className='row pt-5'>
                                <div className='col-md-6'>
                                    <div class="overlay overlay-image-about5"></div>
                                    <Fade delay={1000} left>
                                        <div className='our-work' style={{ padding: "0px 100px 30px 30px" }}>
                                            <h5>Espsofttech mobile development team has exceptional experience in creating native, hybrid, and cross-platform mobile apps.</h5>
                                            <p>Espsofttech is an experienced mobile app development company with a proven track record of delivering effective project management and flexible delivery. We are a leading mobile app development agency known for developing custom mobile apps for iOS and Android platforms. Our mobile app developers have the expertise to create mobile applications using AI and ML technologies. Our expert mobile developers can create the right solutions that will meet your user, business, industry and/or vertical needs.</p>
                                        </div>
                                    </Fade>
                                </div>
                                <div className='col-md-6'>
                                    <div className='our-img'>
                                        <Fade delay={1000} right>
                                            <img src="images/mob-back.jpg" className='back' alt="" />
                                            <img src="images/mob-front.jpg" className='front' alt="" />
                                        </Fade>
                                    </div>
                                </div>

                                <div className='col-md-12 pb-5 pt-5'>
                                    <h3 className='tab-head'>Our Projects</h3>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='project-box  p-3'>
                                                <div className='project-img'>
                                                    {/* <img src="images/btc-app.png" class="" alt="" /> */}
                                                    <div className='row'>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/btc1.png" class="" alt="" />
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/btc2.png" class="" alt="" />
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/btc3.png" class="" alt="" /></div>
                                                    </div>
                                                </div>
                                                <div className='details'>
                                                    <h4>BTC Pari</h4>
                                                    <a href='https://play.google.com/store/apps/details?id=com.btcpari.app' target="_blank">Live URL</a>
                                                    <img src='images/mobile-png.png' className='graph-img' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='project-box  p-3'>
                                                <div className='project-img'>
                                                    {/* <img src="images/ching-app.png" class="" alt="" /> */}
                                                    <div className='row'>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/ching1.png" class="" alt="" />
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/ching2.png" class="" alt="" />
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/ching3.png" class="" alt="" /></div>
                                                    </div>
                                                </div>
                                                <div className='details'>
                                                    <h4>Chalkboard Catering</h4>
                                                    <a href='https://play.google.com/store/apps/details?id=com.chalkboard.catering&hl=en' target="_blank">Live URL</a>
                                                    <img src='images/mobile-png.png' className='graph-img' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='project-box p-3'>
                                                <div className='project-img'>
                                                    {/* <img src="images/coin-cash.webp" class="" alt="" /> */}
                                                    <div className='row'>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/coin1.png" class="" alt="" />
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/coin2.png" class="" alt="" />
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/coin3.png" class="" alt="" /></div>
                                                    </div>
                                                </div>
                                                <div className='details'>
                                                    <h4>Coincash</h4>
                                                    <a href='https://play.google.com/store/apps/details?id=com.espsofttech.coincash' target="_blank">Live URL</a>
                                                    <img src='images/mobile-png.png' className='graph-img' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='project-box  p-3'>
                                                <div className='project-img'>
                                                    {/* <img src="images/catering.webp" class="" alt="" /> */}
                                                    <div className='row'>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/chik1.png" class="" alt="" />
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/chik2.png" class="" alt="" />
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <img src="images/mockup/chik3.png" class="" alt="" /></div>
                                                    </div>
                                                </div>
                                                <div className='details'>
                                                    <h4>
                                                        ChikChing</h4>
                                                    <a href='https://play.google.com/store/apps/details?id=com.espsofttech.utou_flutter_app' target="_blank">Live URL</a>
                                                    <img src='images/mobile-png.png' className='graph-img' />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Crypto Exchange</button>
                                            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Ecommerce</button>
                                            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Food ordering</button>
                                            <button class="nav-link" id="nav-wallet-tab" data-bs-toggle="tab" data-bs-target="#nav-wallet" type="button" role="tab" aria-controls="nav-wallet" aria-selected="false">Crypto wallet</button>
                                            <button class="nav-link" id="nav-mobile-tab" data-bs-toggle="tab" data-bs-target="#nav-mobile" type="button" role="tab" aria-controls="nav-mobile" aria-selected="false">Mobile Application</button>
                                        </div>
                                    </nav>
                                  <div className='row'>
                                  <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"> <h3 className='sub-head'>
                                            Cryptocurrency Exchange platform</h3></div>
                                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <h3 className='sub-head'>Ecommerce Multivendor </h3>
                                        </div>
                                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <h3 className='sub-head'>Online Food Ordering</h3>
                                        </div>
                                        <div class="tab-pane fade" id="nav-wallet" role="tabpanel" aria-labelledby="nav-wallet-tab">
                                        <h3 className='sub-head'>Crypto Wallet</h3>
                                        </div>
                                        <div class="tab-pane fade" id="nav-mobile" role="tabpanel" aria-labelledby="nav-mobile-tab">
                                        <h3 className='sub-head'>Mobile Aoolication</h3>
                                        </div>
                                    </div>
                                  </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}