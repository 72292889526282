import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Fade from 'react-reveal/Fade';
import Carousel from "react-multi-carousel";

export default class Portfolio2 extends Component {

    constructor(props) {
        super(props)

    }
    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

    }
    render() {

        return (
            <>
                <Header />
                <section className='web-background all-bg'>
                    <div className='container-fluid p-0'>
                        <div class="elementor-background-overlay"></div>
                        <div className='portfolio-head'>
                            <h1>Web Development</h1>
                            <p>Helping Businesses build secure and scalable Web Applications, Portals and Solutions.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container-fluid'>

                        <div className='container mt-5 pt-5'>
                            <div className='row pt-5'>
                                <div className='col-md-6'>
                                    <div class="overlay overlay-image-about5"></div>
                                    <Fade delay={1000} left>
                                        <div className='our-work' style={{ padding: "0px 100px 30px 30px" }}>
                                            <h5>Helping Businesses build secure and scalable Web Applications, Portals and Solutions.</h5>
                                            <p>We provide a wide range of services. At Espsofttech, we dig deep and understand our client’s objectives and unique business challenges to provide the best-suited web app solution.
                                                Espsofttech is the best web application development company in India, providing cutting-edge technological solutions for next-generation web applications. Our extensive experience in web application development services includes the tools and techniques and many more.

                                            </p>
                                        </div>
                                    </Fade>
                                </div>
                                <div className='col-md-6'>
                                    <div className='our-img'>
                                        <Fade delay={1000} right>
                                            <img src="images/web-back.jpg" className='back' alt="" />
                                            <img src="images/web-front2.jpg" className='front' alt="" />
                                        </Fade>
                                    </div>
                                </div>

                                <div className='col-md-12 pb-5 pt-5'>
                                    <h3 className='tab-head'>Our Projects</h3>
                                    <div className='row'>
                                    <Fade left>
                                        <div className='col-md-6'>
                                            <div className='project-box'>
                                                <div className='project-img'>
                                                    <img src="images/snapx.png" class="" alt="" />
                                                </div>
                                                <div className='details'>
                                                    <h4>Snapx</h4>
                                                    <a href='https://app.snapxPlatform.com/' target="_blank">Live URL</a>
                                                    <img src='images/webD.png' className='graph-img' />
                                                </div>
                                            </div>
                                        </div>
                                        </Fade>
                                        <Fade right>
                                        <div className='col-md-6'>
                                                    <div className='project-box'>
                                                        <div className='project-img'>
                                                            <img src="images/resouk.png" class="" alt="" />
                                                        </div>
                                                        <div className='details'>
                                                            <h4>ReSounk</h4>
                                                            <a href='https://resouk.com/' target="_blank">Live URL</a>
                                                            <img src='images/webD.png' className='graph-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                                </Fade>
                                                <Fade left>
                                                <div className='col-md-6'>
                                                    <div className='project-box'>
                                                        <div className='project-img'>
                                                            <img src="images/onebig.png" class="" alt="" />
                                                        </div>
                                                        <div className='details'>
                                                            <h4>One big win</h4>
                                                            <a href='https://www.onebigwin.co.uk/' target="_blank">Live URL</a>
                                                            <img src='images/webD.png' className='graph-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                                </Fade>
                                                <Fade right>
                                                <div className='col-md-6'>
                                                    <div className='project-box'>
                                                        <div className='project-img'>
                                                            <img src="images/dokan.png" class="" alt="" />
                                                        </div>
                                                        <div className='details'>
                                                            <h4>Q8 dokan</h4>
                                                            <a href='https://q8dokan.com/store/' target="_blank">Live URL</a>
                                                            <img src='images/webD.png' className='graph-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                                </Fade>
                                                <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/project4.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Odd Fellows NFTs</h4>
                                                                        <a href='https://oddfellowsnft.com/'  target="_blank">Live URL</a>
                                                                        <img src='images/webD.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/project5.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>My NFT House Mint</h4>
                                                                        <a href='https://mynfthousemint.co.uk/'  target="_blank">Live URL</a>
                                                                        <img src='images/webD.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </Fade>
                                    </div>
                                    {/* <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Job Portal</button>
                                            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Real Estate</button>
                                            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Ecommerce</button>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <h3 className='sub-head'>Job Portal Platform</h3>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='project-box'>
                                                        <div className='project-img'>
                                                            <img src="images/snapx.png" class="" alt="" />
                                                        </div>
                                                        <div className='details'>
                                                            <h4>Snapx</h4>
                                                            <a href='https://app.snapxPlatform.com/'>Live URL</a>
                                                            <img src='images/webD.png' className='graph-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <h3 className='sub-head'>Real Estate Platform</h3>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='project-box'>
                                                        <div className='project-img'>
                                                            <img src="images/resouk.png" class="" alt="" />
                                                        </div>
                                                        <div className='details'>
                                                            <h4>ReSounk</h4>
                                                            <a href='https://resouk.com/'>Live URL</a>
                                                            <img src='images/webD.png' className='graph-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                            <h3 className='sub-head'>Ecommerce website</h3>
                                            <div className='row'>
                                            <div className='col-md-6'>
                                                    <div className='project-box'>
                                                        <div className='project-img'>
                                                            <img src="images/onebig.png" class="" alt="" />
                                                        </div>
                                                        <div className='details'>
                                                            <h4>One big win</h4>
                                                            <a href='https://www.onebigwin.co.uk/'>Live URL</a>
                                                            <img src='images/webD.png' className='graph-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='project-box'>
                                                        <div className='project-img'>
                                                            <img src="images/dokan.png" class="" alt="" />
                                                        </div>
                                                        <div className='details'>
                                                            <h4>Q8 dokan</h4>
                                                            <a href='https://q8dokan.com/store/'>Live URL</a>
                                                            <img src='images/webD.png' className='graph-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}